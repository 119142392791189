import React from "react";
import HeaderSection from "../Common/HeaderSection";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import HeaderMeta from "../Common/HeaderMeta";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: {
                customer: false,
                distributor: false
            }
        }
        this.changeState = this.changeState.bind(this);
        this.changeChecked = this.changeChecked.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    changeChecked(e) {
        this.setState({
            ...this.state,
            type: {
                ...this.state.type,
                [e.target.name]: !this.state.type[e.target.name]
            }
        })
    }

    submit() {

        fetch('https://7lzqbmoaak.execute-api.ap-south-1.amazonaws.com/contact', {
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(careerFormResponse => careerFormResponse.json())
            .then(careerFormResponse => {
                this.setState({
                    ...this.state,
                    formSubmitted: true
                })
            })

        console.log(this.state)

        // TODO: Avidant Finish Submit
        this.setState({
            ...this.state,
            submitted: true
        })
    }

    render() {
        return (
            <div>
                <HeaderMeta
                    title='Contact Us | Klaruss by Techtran | Lenses for everyone'
                    description="Contact Klaruss by Techtran about our line of world class products including Semi Finished, Photo Chromatic, Blue Cut and Progressive Lenses"
                    slug='contact-us'
                />
                <HeaderSection image='/assets/headers/contact.png' title='CONTACT US' subtitle='WANT TO KNOW MORE ABOUT US? NEED HELP?' body='For more information about our products, or further details about our personal manufacturing services & the range of Stock Lenses we can supply, please reach out to us…'/>
                <Container>
                    <Row>
                        <Col sm>
                            <h5>DROP US A MESSAGE</h5>
                            <Form>
                                <Row>
                                    <Col sm>
                                        <Form.Group className="mb-3" controlId="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="input"
                                                placeholder="Enter your first name"
                                                name='firstName'
                                                value={this.state.firstName}
                                                onChange={this.changeState}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm>
                                        <Form.Group className="mb-3" controlId="lastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="input"
                                                placeholder="Enter your last name"
                                                name='lastName'
                                                value={this.state.lastName}
                                                onChange={this.changeState}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter your email address"
                                                name='email'
                                                value={this.state.email}
                                                onChange={this.changeState}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm>
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter your mobile number"
                                                name='phone'
                                                value={this.state.phone}
                                                onChange={this.changeState}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                <Form.Group className="mb-3" controlId="enquiryType">
                                    <Form.Label>Enquiry Type</Form.Label><br />
                                    <Form.Check
                                        inline
                                        label="Customer"
                                        name="customer"
                                        type='checkbox'
                                        checked={this.state.type.customer}
                                        onChange={this.changeChecked}
                                    />
                                    <Form.Check
                                        inline
                                        label="Distributor"
                                        name="distributor"
                                        type='checkbox'
                                        checked={this.state.type.distributor}
                                        onChange={this.changeChecked}
                                    />
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Want to know more about your company"
                                        name='message'
                                        value={this.state.message}
                                        onChange={this.changeState}
                                    />
                                </Form.Group>
                                </Row>
                                <Button variant="outline-secondary" className='submit-button' onClick={this.submit}>Send Message</Button>
                                {this.state.submitted && <Alert variant='success'>Your message has been sent!</Alert>}
                            </Form>
                        </Col>
                        <Col sm>
                            <Image src='/assets/contact/form-support.png' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
import React from "react";
import Container from "react-bootstrap/Container";

export default class HeaderSection extends React.Component {
    render() {
        const backgroundStyle = {
            backgroundImage: 'url(' + this.props.image + ')',
        }
        return (
            <div>
                <div className='backgroundImageSection text-center' style={backgroundStyle}>
                    <Container>
                        <h2>{this.props.title}</h2>
                        <h4>{this.props.subtitle}</h4>
                        <p>{this.props.body}</p>
                    </Container>
                </div>
            </div>
        );
    }
}
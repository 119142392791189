import React from "react";
import HeaderSection from "../Common/HeaderSection";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import HeaderMeta from "../Common/HeaderMeta";

export default class Products extends React.Component {
    render() {

        const products = [
            {
                key: '1.498',
                name: '1.498',
                material: 'Mitsui Chemicals, Japan; PPG, USA',
                abbe: '59',
                gravity: '1.32',
                color: 'White',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '4.9',
                reviewCount: 267
            },
            {
                key: '1.56',
                name: '1.56',
                material: 'Mitsui Chemicals, Japan; Monomer from South Korea',
                abbe: '38.5',
                gravity: '1.17',
                color: 'White',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '5.0',
                reviewCount: 179
            },
            {
                key: '1.56-pg',
                name: '1.56 (PG)',
                material: 'Sunsensor, Mitsui Chemicals and Monomer from South Korea',
                abbe: '38',
                gravity: '1.16',
                color: 'Grey & brown',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '5.0',
                reviewCount: 205
            },
            {
                key: '1.56-420',
                name: '1.56 UV420 Blue block',
                material: 'Mitsui Chemicals, Japan; Monomer from South Korea',
                abbe: '39-41',
                gravity: '1.03',
                color: 'Blue-white',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '5.0',
                reviewCount: 205
            },
            {
                key: '1.56-pc-bb',
                name: '1.56 Photochromatic blue block',
                material: 'Monomer from South Korea',
                abbe: '39-41',
                gravity: '1.03',
                color: 'Blue-grey',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '5.0',
                reviewCount: 209
            },
            {
                key: '1.61-white-400',
                name: '1.61 white UV400',
                material: 'Monomer from South Korea; Mitsui Chemicals, Japan',
                abbe: '33',
                gravity: '1.34',
                color: 'Blue-white',
                coating: 'UC',
                diameter: '65, 70, 75',
                rating: '5.0',
                reviewCount: 209
            },
        ]

        return (
            <div>
                <HeaderMeta
                    title='Products | Klaruss by Techtran | Lenses for everyone'
                    description="View Klaruss by Techtran's line of world class products including Semi Finished Blanks, Photo Chromatic, Blue Cut and Progressive Lenses"
                    slug='products'
                />
                <HeaderSection image='/assets/headers/products.png' title='LENSES CRAFTED FOR THE WORLD TO SEE.'/>
                <Container>
                    <div className='spacer' />
                    <h3>Semi Finished Lenses</h3>
                    <Container>
                        <Tabs defaultActiveKey="1.498" id="uncontrolled-tab-example" className="mb-3">
                            {products.map((product, idx) => {
                                return (
                                    <Tab eventKey={product.key} title={product.name}>
                                        <Row>
                                            <Col sm>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            Material
                                                        </td>
                                                        <td>
                                                            {product.material}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Abbe value</td>
                                                        <td>{product.abbe}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Specific gravity</td>
                                                        <td>{product.gravity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color</td>
                                                        <td>{product.color}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Coating available</td>
                                                        <td>{product.coating}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Diameter available (mm)</td>
                                                        <td>{product.diameter}</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col sm>
                                                <Image src='/assets/product.png' className='img-fluid' />
                                            </Col>
                                        </Row>
                                    </Tab>
                                );
                            })}
                        </Tabs>
                    </Container>
                    <div className='spacer' />
                    <Row>
                        <Col className='my-auto' sm>
                            <p>
                                We offer a wide progressive and single vision product range of all indexes from 1.498 to 1.67 for your RX lens orders. We have production capacity to fulfill the needs of Indian market as well as world requirement of semi-finished lens.
                            </p>
                        </Col>
                        <Col sm>
                            <Image src='/assets/products/1.png' className='img-fluid' />
                        </Col>
                    </Row>
                    <div className='spacer' />
                    <Row>
                        <Col sm>
                            <Image src='/assets/products/2.png' className='img-fluid' />
                        </Col>
                        <Col sm className='my-auto'>
                            <p>
                                In order to cater to wide variety of customer needs, our partners Mitsui and PPG have integrated its diverse technologies at each of its R&D laboratories to create monomers of highest quality. Polymer lens monomers provides lenses of extremely optimized optical characteristics.
                            </p>
                        </Col>
                    </Row>
                    <div className='spacer' />
                    <div className='spacer' />
                    <Row>
                        <h4>THE KLARUSS EXPERIENCE</h4>
                        <ul>
                            <li>High Quality Products from one of the global market’s most extensive offerings.</li>
                            <li>Klaruss is, and Will be flexible and responsive towards wholesalers/distributors and retailers for customizing products and packaging.</li>
                            <li>Klaruss commitment to its customers around the globe; guaranteed service!</li>
                            <li>Superior Logistics Services, specializing in the optical market.</li>
                        </ul>
                    </Row>
                    <div className='spacer' />
                </Container>
            </div>
        );
    }
}
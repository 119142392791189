import React from "react";
import HomeCarousel from "./HomeCarousel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class Home extends React.Component {
    render() {
        return (
            <div>
                <HomeCarousel />
                <div className='spacer' />
                <Container>
                    <Row>
                        <Col sm className='text-justify my-auto'>
                            <h3>About Us</h3><br />
                            <p>
                                We’re a dynamic group of professionals passionate about making top quality eyeglasses lenses accessible to the global market. Our team brings years of valuable experience in the global ophthalmic lens industry and precious insights that enable us to deliver the best products.
                            </p>
                            <Button variant='outline-primary' as={Link} to='/about-us'>Know More</Button>
                        </Col>
                        <Col sm>
                            <Image src='/assets/home/about-us.png' className='img-fluid' />
                        </Col>
                    </Row>
                    <div className='spacer' />
                    <hr />
                    <div className='spacer' />
                    <Row className='text-center'>
                        <h3>Partners</h3><br />
                        <div className='spacer' />
                        <div className='image-center'>
                            <Image src='/assets/home/partners.png' style={{width: '40%'}} className='img-fluid'/>
                        </div>
                    </Row>
                    <div className='spacer' />
                    <hr />
                    <div className='spacer' />
                    <Row className='text-center'>
                        <h3>Products</h3><br />
                        <div className='spacer' />
                        <Col sm>
                            <Row>
                                <Image src='/assets/home/product-1.png' className='img-fluid' />
                            </Row>
                            <Row className='side-padded'>
                                <p className='text-center'>
                                    SEMI FINISHED SINGLE VISION BLANKS SPHERICAL
                                </p>
                            </Row>
                        </Col>
                        <Col sm>
                            <Row>
                                <Image src='/assets/home/product-2.png' className='img-fluid' />
                            </Row>
                            <Row className='side-padded'>
                                <p className='text-center'>
                                    SEMI FINISHED ROUND TOP & FLAT TOP BIFOCALS
                                </p>
                            </Row>
                        </Col>
                        <Col sm>
                            <Row>
                                <Image src='/assets/home/product-3.png' className='img-fluid' />
                            </Row>
                            <Row className='side-padded'>
                                <p className='text-center'>
                                    SHORT CORRIDOR PROGRESSIVE BLANKS (REVA)
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <div className='spacer' />
                    <div className='spacer' />
                    <Row className='text-center'>
                        <Col sm>
                            <Row className='padded-home-grid-text'>
                                <h4>CAREERS</h4>
                                <p>
                                    If you look at the world through a different lens and can offer us a fresh perspective, Get In Touch With Us.
                                </p>
                                <Button variant='outline-secondary' as={Link} to='/careers'>Apply Now</Button>
                            </Row>
                            <Row className='padded-home-grid-image'>
                                <img src='/assets/home/end-2.png' className='fit-height' alt=''/>
                            </Row>
                        </Col>
                        <Col sm>
                            <Row className='padded-home-grid-image'>
                                <img src='/assets/home/end-1.png' className='fit-height' alt=''/>
                            </Row>
                            <Row className='padded-home-grid-text'>
                                <h4>BECOME A DISTRIBUTOR</h4>
                                <p>
                                    Looking to grow your wholesale or distribution business? Look no further! Contact us to learn more about how Klaruss can help and support you in your local market, providing you with the finest of tools for lens distribution success with our full line of digital surfaced and stock lenses.
                                </p>
                                <Button variant='outline-secondary' as={Link} to='/contact-us'>Become a Distributor</Button>
                            </Row>
                        </Col>
                        {/*<table className='grid-table'>*/}
                        {/*    <tbody>*/}
                        {/*        <tr>*/}
                        {/*            <td style={style}>*/}
                        {/*                <h4>CAREERS</h4>*/}
                        {/*                <p>*/}
                        {/*                    If you look at the world through a different lens and can offer us a fresh perspective, Get In Touch With Us.*/}
                        {/*                </p>*/}
                        {/*                <Button variant='outline-secondary' as={Link} to='/careers'>Apply Now</Button>*/}
                        {/*            </td>*/}
                        {/*            <td rowSpan={2}>*/}
                        {/*                <img src='/assets/home/end-2.png' className='fit-height' alt=''/>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <td rowSpan={2}>*/}
                        {/*                <img src='/assets/home/end-1.png' className='fit-height' alt=''/>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <td style={style}>*/}
                        {/*                <h4>BECOME A DISTRIBUTOR</h4>*/}
                        {/*                <p>*/}
                        {/*                    Looking to grow your wholesale or distribution business? Look no further! Contact us to learn more about how Klaruss can help and support you in your local market, providing you with the finest of tools for lens distribution success with our full line of digital surfaced and stock lenses.*/}
                        {/*                </p>*/}
                        {/*                <Button variant='outline-secondary' as={Link} to='/contact-us'>Become a Distributor</Button>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                    </Row>
                    {/*<div className='spacer' />*/}
                    {/*<Row className='text-center'>*/}
                    {/*    <a href='/assets/KlarussLensesBrochure.pdf' target='_blank' className='text-decoration-none text-black'>Click here to view our brochure.</a>*/}
                    {/*</Row>*/}
                    <div className='spacer' />
                </Container>
            </div>
        );
    }
}
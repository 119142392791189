import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";

export default class Footer extends React.Component {
    render() {
        return (
            <div className='bg-dark footer'>
                <Container className='padded-bottom'>
                    <Row>
                        <Col sm>
                            <div className='bg-gray text-center padded-bottom'>
                                <Image src='/assets/footer.png' className='padded padded-top' width='85%'/>
                                Crafted for the world to see.
                            </div>
                        </Col>
                        <Col sm className='padded-top'>
                            <h4>INFORMATION</h4>
                            <Link className='footer-link' to='/'>Home</Link><br />
                            <Link className='footer-link' to='/about-us'>About Us</Link><br />
                            <Link className='footer-link' to='/products'>Products</Link><br />
                            <Link className='footer-link' to='/careers'>Careers</Link><br />
                            <Link className='footer-link' to='/contact-us'>Contact Us</Link><br />
                        </Col>
                        <Col sm className='padded-top'>
                            <h4>LENSES</h4>
                            <Link className='footer-link' to='/products'>Semi Finished Lenses</Link>
                        </Col>
                        <Col sm className='padded-top'>
                            <Row>
                                <a href='https://goo.gl/maps/ibwL3jt8xGr1GEFx6' className='footer-link'>
                                    2011, Emerald House,<br />
                                    S.D. Road, Secunderabad,<br />
                                    Telangana - 500003
                                </a>
                            </Row>
                            <Row>
                                <div className='spacer' />
                            </Row>
                            <Row>
                                <a href='tel:+917702841518' className='footer-link'>+91 770 284 1518</a><br />
                                <a href='tel:+919573803500' className='footer-link'>+91 957 380 3500</a><br />
                                <a href='tel:+918185815252' className='footer-link'>+91 818 581 5252</a>
                            </Row>
                            <Row>
                                <div className='spacer' />
                            </Row>
                            <Row>
                                <a href='mailto:info@klaruss.com' className='footer-link'>info@klaruss.com</a>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
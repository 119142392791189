import React from "react";
import {Helmet} from "react-helmet";

export default class HeaderMeta extends React.Component {
    render() {
        const title = this.props.title ? this.props.title : 'Klaruss | Lenses for everyone | Crafted for the world to see';
        const description = this.props.description ? this.props.description : 'Techtran brings to you Klaruss. Leaders in Semi Finished Blanks, Photo Chromatic, Blue Cut, Progressive, Bifocal, and Single Vision Lenses';
        const url = 'https://www.klaruss.com/' + (this.props.slug ? this.props.slug : '');

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description}
                    />
                    <link rel="canonical" href={url}/>

                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={description}/>
                    <meta property="og:url" content={url}/>

                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                </Helmet>
            </div>
        );
    }
}
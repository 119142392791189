import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class HomeCarousel extends React.Component {
    render() {
        return (
            <div>
                <Carousel>
                    <Carousel.Item>
                        <HomeCarouselSlide image='head-1' alt='First Slide' text='KLARUSS LENSES FOR EVERYONE.'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <HomeCarouselSlide image='head-2' alt='Second Slide' text='ENABLING THE GIFT OF VISION GLOBALLY FOR 25 YEARS.'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <HomeCarouselSlide image='head-3' alt='Third Slide' text='DESIGNED WITH AN INSIGHT INTO THE FUTURE.'/>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

class HomeCarouselSlide extends React.Component {
    render() {
        const style = {
            backgroundImage: 'url("/assets/home/' + this.props.image + '.png")',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'auto',
            height: '700px',
            backgroundPosition: 'center'

    }
        return (
            <div style={style}>
                <Image
                    className="d-block w-100 invisible"
                    src={'/assets/home/' + this.props.image + '.png'}
                    alt={this.props.alt}
                />
                <div className='center-div'>
                    <div>
                        <strong className='carousel-slide-header'>{this.props.text}</strong>
                    </div>
                    <div className='spacer' />
                    <div>
                        <Button variant='outline-primary' as={Link} to='/contact-us'>Enquire Now</Button>
                    </div>
                </div>
            </div>
        );
    }
}
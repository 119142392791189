import React from "react";
import HeaderSection from "../Common/HeaderSection";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Timeline from "./Timeline";
import HeaderMeta from "../Common/HeaderMeta";

export default class About extends React.Component {
    render() {
        return (
            <div>
                <HeaderMeta
                    title='About Us | Klaruss by Techtran | Lenses for everyone'
                    description='Learn about Klaruss by Techtran and our world class products including Semi Finished, Photo Chromatic, Blue Cut and Progressive Lenses'
                    slug='about-us'
                />
                <HeaderSection image='/assets/headers/about.png' title='ABOUT US' />
                <Container>
                    <div className='spacer' />
                    <Row>
                       <Col className='text-justify my-auto' sm>
                            <p>
                                We’re a dynamic group of professionals passionate about making top quality eyeglasses lenses accessible to the global market. Our team brings years of valuable experience in the global ophthalmic lens industry and precious insights that enable us to deliver the best products. We have the production capacity of 4,50,000 lenses per month.
                            </p>
                            <p>
                                With a global presence, advanced manufacturing and quality control, the fastest production lead times in the industry, and the ability to fulfill orders worldwide, we are the perfect lens partner for you.
                            </p>
                           <p>
                                We offer finest selection of semi-finished blanks and stock lenses. With advanced manufacturing, superior quality control and ability to fulfill orders worldwide, we are one stop shop for all your lens needs. Klaruss stands behind every pair of lenses produced and sold, giving you and your customers confidence and ability to see the world better
                           </p>
                       </Col>
                       <Col sm>
                            <Image src='/assets/about/1.png' className='img-fluid'/>
                       </Col>
                    </Row>
                    <Row>
                        <div className='spacer' />
                    </Row>
                    <Row>
                        <Col sm>
                            <Image src='/assets/about/2.png' className='img-fluid'/>
                        </Col>
                        <Col className='my-auto' sm>
                            <h4>VISION</h4>
                            <p>
                                Secure undisputed global category leadership as THE destination of choice for eye care solutions, leveraging our best-in-class customer value proposition.
                            </p>
                            <p>
                                At Klaruss, we make a leapfrog over our competitors’ products by offering the most advanced technological lens solution available to the marketplace at any given time, and delivering unparalleled customer service.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <div className='spacer' />
                    </Row>
                    <Row>
                        <Col className='my-auto' sm>
                            <Row>
                                <h4>MISSION</h4>
                                <p>
                                    Provide unique high-quality and affordable eye care solutions to more and more customers all over the world and turn them into fans.
                                </p>
                            </Row>
                            <Row className='text-center'>
                                <Col sm>
                                    <Image src='/assets/about/4.png' width='75%' className='img-fluid'/>
                                </Col>
                                <Col sm>
                                    <Image src='/assets/about/5.png' width='75%' className='img-fluid'/>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Image src='/assets/about/3.png' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
                <div className='spacer' />
                <Timeline />
            </div>
        );
    }
}
import React from "react";
import HeaderSection from "../Common/HeaderSection";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import HeaderMeta from "../Common/HeaderMeta";
import {ReCAPTCHA} from "react-google-recaptcha";

export default class Careers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.changeState = this.changeState.bind(this);
        this.submit = this.submit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.changeRecaptcha = this.changeRecaptcha.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    submit() {
        const fileName = new Date().toISOString().split('T')[0] + '/' + this.state.firstName + '_' + this.state.lastName + '/' + this.state.resume.name;

        fetch('https://7lzqbmoaak.execute-api.ap-south-1.amazonaws.com/upload', {
            method: 'POST',
            body: JSON.stringify({
                type: 'resume',
                fileName: fileName,
                contentType: this.state.resume.type
            })
        })
            .then(uploadUrlResponse => uploadUrlResponse.json())
            .then(uploadUrlResponse => {
                fetch(uploadUrlResponse, {
                    method: 'PUT',
                    body: this.state.resume,
                    headers: new Headers({'Content-Type': this.state.resume.type, 'Access-Control-Allow-Origin': '*'}),
                })
                    .then(response => response.text())
                    .then(response => {
                        this.setState({
                            ...this.state,
                            fileUploadComplete: true
                        })
                    })
            })

        fetch('https://7lzqbmoaak.execute-api.ap-south-1.amazonaws.com/careers', {
            method: 'POST',
            body: JSON.stringify({...this.state, fileName: fileName})
        })
            .then(careerFormResponse => careerFormResponse.json())
            .then(careerFormResponse => {
                    this.setState({
                        ...this.state,
                        formSubmitted: true
                    })
                })
        }

    uploadFile(e) {
        this.setState({
            ...this.state,
            resume: e.target.files[0]
        })
    }

    changeRecaptcha(e) {
        this.setState({
            ...this.state,
            recaptcha: e
        })
    }

    render() {
        return (
            <div>
                <HeaderMeta
                    title='Careers | Klaruss by Techtran | Lenses for everyone'
                    description="Work on Klaruss by Techtrans line of world class products including Semi Finished Blanks, Photo Chromatic, Blue Cut and Progressive Lenses"
                    slug='careers'
                />
                <HeaderSection image='/assets/headers/careers.png' title='CAREERS'/>
                <Container>
                    <Row className='text-center'>
                        <h4>WE ALWAYS KEEP AN EYE OUT FOR TALENT</h4>
                    </Row>
                    <Row className='text-center'>
                        <p>If you look at the world through a different lens and can offer us a fresh perspective, Get In Touch With Us.</p>
                    </Row>
                    <Row>
                        <h5>SEND US YOUR RESUME</h5>
                        <Form>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="input"
                                            placeholder="Enter your first name"
                                            name='firstName'
                                            value={this.state.firstName}
                                            onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="input"
                                            placeholder="Enter your last name"
                                            name='lastName'
                                            value={this.state.lastName}
                                            onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter your email address"
                                            name='email'
                                            value={this.state.email}
                                            onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter your mobile number"
                                            name='phone'
                                            value={this.state.phone}
                                            onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="intro">
                                    <Form.Label>Tell us a bit about yourself</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="We want to know more about you"
                                        name='intro'
                                        value={this.state.intro}
                                        onChange={this.changeState}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="resume">
                                    <Form.Label>Upload your Resume</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name='resume'
                                        onChange={this.uploadFile}
                                    />
                                </Form.Group>
                            </Row>
                            <Button variant="outline-secondary" className='submit-button' onClick={this.submit}>Submit</Button>
                            {this.state.formSubmitted && this.state.fileUploadComplete && <Alert variant='success'>Your resume has been received!</Alert>}
                        </Form>
                    </Row>
                </Container>
            </div>
        );
    }
}
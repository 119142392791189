import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Container from "react-bootstrap/Container";
import {BsBuilding, BsEye, BsEyeglasses, BsFillEyeFill} from "react-icons/bs";

export default class Timeline extends React.Component {
    render() {

        const data = [
            {
                date: '1989',
                icon: <BsBuilding />,
                title: 'Year of Establishment'
            },
            {
                date: '1992',
                icon: <BsEye />,
                title: 'Started manufacturing Single Vision Lenses'
            },
            {
                date: '1994',
                icon: <BsEyeglasses />,
                title: 'Started manufacturing Bifocal Lenses'
            },
            {
                date: '1996',
                icon: <BsEyeglasses />,
                title: 'Started manufacturing Multi Focal Lenses'
            },
            {
                date: '1999',
                icon: <BsFillEyeFill />,
                title: 'Started manufacturing Photo Chromatic Lenses'
            },
            {
                date: '2007',
                icon: <BsEyeglasses />, // TODO
                title: 'Introduced Hard and Multi coating Lenses'
            },
            {
                date: '2010',
                icon: <BsEyeglasses />,
                title: 'Introduced MR Series Lenses'
            },
            {
                date: '2021',
                icon: <BsBuilding />,
                title: 'Change of Management'
            },
        ]

        return (
            <div>
                <Container>
                    <VerticalTimeline lineColor='grey'>
                        {data.map((item, idx) => {
                            return (
                                <VerticalTimelineElement
                                    date={item['date']}
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={item['icon']}
                                >
                                    <strong className="vertical-timeline-element-title">{item['title']}</strong>
                                </VerticalTimelineElement>
                            )
                        })}
                    </VerticalTimeline>
                </Container>
            </div>
        );
    }
}
import React from "react";

export default class PageNotFound extends React.Component {
    render() {
        return (
            <div>
                PageNotFound
            </div>
        );
    }
}
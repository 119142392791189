import Header from "./components/Common/Header";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import About from "./components/About/About";
import Products from "./components/Products/Products";
import Home from "./components/Home/Home";
import Careers from "./components/Careers/Careers";
import Contact from "./components/Contact/Contact";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Footer from "./components/Common/Footer";

function App() {
  return (
    <div>
        <BrowserRouter>
            <Header />
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/about-us">
                    <About />
                </Route>
                <Route path="/products">
                    <Products />
                </Route>
                <Route path="/careers">
                    <Careers />
                </Route>
                <Route path="/contact-us">
                    <Contact />
                </Route>
                <Route path="/">
                    <PageNotFound />
                </Route>
            </Switch>
            <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
